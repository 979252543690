import DraggableBlock from '../editor/DraggableBlock.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Table from '@tiptap/extension-table'
const customTable = Table.extend({
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  },
})
export default customTable
