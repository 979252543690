import DraggableBlock from '../editor/DraggableBlock.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { Image } from '@tiptap/extension-image'
const customImage = Image.extend({
  draggable: true,
  name: 'video',
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  },
  addCommands() {
    return {
      setVideo: options => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options,
        })
      },
    }
  },
})
export default customImage
