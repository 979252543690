<template>
  <node-view-wrapper
    :id="nodeId"
    :class="{
      'draggable-item': (editable && allowAddBlock) || draggable,
      active: isActive,
    }"
    @click.native="selectBlock()"
  >
    <div
      v-if="(editable && allowAddBlock) || draggable"
      class="block-options z-10 flex items-center justify-end select-none"
      contenteditable="false"
    >
      <b-dropdown
        v-if="editable && allowAddBlock"
        ref="addblock"
        class="add-block"
        style="z-index: 11;"
        aria-role="list"
        position="is-bottom-right"
        @active-change="changeActive($event)"
      >
        <template #trigger="{ active }">
          <button class="h-6 w-6 border border-gray-200 rounded-md flex items-center justify-center">
            <fw-icon-add-line class="w-4 h-4 text-gray-300" />
          </button>
        </template>
        <b-dropdown-item v-if="nodeId" paddingless aria-role="listitem">
          <fw-button type="simple" size="sm" label="Comentar" @click.native="comment()">
            <template #icon>
              <fw-icon-message class="w-5 h-5" />
            </template>
            {{ $t('comment') }}
          </fw-button>
        </b-dropdown-item>
        <div class="leading-5 text-xs font-semibold text-gray-400 px-2">{{ $t('content_block') }}</div>
        <EditorBlockItem
          v-for="(block, b) in availableContentBlocks"
          :key="'addblock' + b"
          :language="language"
          :item="{ type: block, name: $t('blocks.' + block), attrs: { level: 1 } }"
          @click.native="addBlock(nodeId, block)"
        />
      </b-dropdown>
      <b-dropdown
        v-if="draggable && editable"
        class="more-options"
        style="z-index: 11;"
        draggable="true"
        data-drag-handle
        aria-role="list"
        position="is-bottom-right"
      >
        <template #trigger="{ active }">
          <div class="editor-drag-handle"></div>
        </template>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button label="Eliminar" type="simple" size="sm" @click.native="addBlock(nodeId, 'duplicate')">
            <template #icon>
              <fw-icon-copy class="w-5 h-5" />
            </template>
            {{ $t('duplicate') }}
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button label="Eliminar" type="simple" size="sm" @click.native="deleteBlock()">
            <template #icon>
              <fw-icon-trash class="w-5 h-5" />
            </template>
            {{ $t('delete') }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      contenteditable="false"
      class="absolute cursor-pointer -right-2 top-1.5 flex gap-1 text-xs text-gray-500 opacity-0 transition-all"
      @click="selectBlock()"
    >
      <fw-icon-discuss class="w-4 h-4" />
      <span class="block_comments w-5 inline-block text-left" :data-id="nodeAttributes.id">0</span>
    </div>
    <div v-if="nodeType == 'video' && nodeAttributes.src">
      <video-player
        :ref="'video-' + nodeAttributes.id"
        class="w-full h-auto"
        :src="getFileViewUrl(nodeAttributes.src)"
        :type="nodeAttributes.src.mimetype"
      />
    </div>
    <div v-else-if="nodeType == 'image' && nodeAttributes.src">
      <div v-if="downloadImages" class="absolute -top-9 -right-3">
        <fw-button type="link" size="xs" icon="download" @click.native="donwloadFile()">
          {{ $t('download_image') }}
        </fw-button>
      </div>
      <img
        v-if="typeof nodeAttributes.src == 'string' || filesCache[nodeAttributes.src.key]"
        :src="getImageSrc(nodeAttributes.src)"
        :alt="nodeAttributes.alt"
        class="w-full h-auto"
        @click="selectBlock()"
      />
      <div v-else>
        <fw-icon-image class="text-gray-300 w-12 h-12" />
      </div>
    </div>
    <div
      v-else-if="nodeType == 'image' || nodeType == 'video'"
      class="border-2 border-dotted border-gray-200 rounded-lg transition-all hover:border-gray-300 flex flex-col items-center gap-5 pb-10 pt-14"
    >
      <fw-icon-image v-if="nodeType == 'image'" class="text-gray-300 w-12 h-12" />
      <fw-icon-video v-if="nodeType == 'video'" class="text-gray-300 w-12 h-12" />
      <Uploader
        v-if="editable"
        :is-docked="true"
        :layout="'simple'"
        :label="nodeType == 'image' ? $t('upload_image') : $t('upload_video')"
        :reference-id="'upload_' + nodeAttributes.id"
        :is-custom="true"
        :limit="1"
        :allowed="nodeType == 'image' ? 'images' : 'videos'"
        :clear-after="true"
        bucket-code="files"
        bucket-type="file"
        input-id="upload"
        class="text-gray-500"
        @upload="uploadFile($event)"
      />
    </div>
    <node-view-content
      v-else
      :as="htmlType"
      class="editor-table no-draggable-content content-editor text-gray-700"
      :class="{ 'editor-task-list': nodeType == 'taskList' }"
    />
  </node-view-wrapper>
</template>

<script>
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import EditorBlockItem from './EditorBlockItem.vue'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import Vue from 'vue'
export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
    Uploader,
    EditorBlockItem,
    VideoPlayer,
  },
  props: nodeViewProps,
  data() {
    return {
      isActive: false,
      filesCache: {},
    }
  },
  computed: {
    language() {
      return this.editor.options.language
    },
    downloadImages() {
      return this.editor.options.downloadImages || false
    },
    pageKey() {
      return this.editor.options.pageKey
    },
    versionKey() {
      return this.editor.options.versionKey
    },
    nodeId() {
      return this.node.attrs.id
    },
    nodeType() {
      return this.node.type.name
    },
    nodeAttributes() {
      return this.node.attrs
    },
    htmlType() {
      switch (this.nodeType) {
        case 'heading':
          return this.headingLevel ? `h${this.headingLevel}` : 'h1'
        case 'table':
          return 'table'
        case 'bulletList':
          return 'ul'
        case 'taskList':
          return 'ul'
        case 'orderedList':
          return 'ol'
        case 'codeBlock':
          return 'pre'
        case 'blockquote':
          return 'blockquote'
        default:
          return 'div'
      }
    },
    headingLevel() {
      if (this.nodeType == 'heading' && this.node.attrs.level) {
        return this.node.attrs.level
      }
      return null
    },
    user() {
      return this.$store.getters.getUser
    },
    availableContentBlocks() {
      return this.editor.options.availableContentBlocks
    },
    editable() {
      return this.editor.options.editable
    },
    draggable() {
      return this.editor.options.draggable || false
    },
    allowAddBlock() {
      return this.editor.options.allowAddBlock
    },
  },
  watch: {
    nodeAttributes: {
      handler() {
        if (this.nodeType == 'image' || this.nodeType == 'video') {
          this.loadFileToCache(this.nodeAttributes.src)
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.nodeType == 'image' || this.nodeType == 'video') {
      //get files from node attributes
      this.loadFileToCache(this.nodeAttributes.src)
    }
  },
  methods: {
    donwloadFile() {
      let file = this.nodeAttributes.src
      if (typeof file == 'string') {
        window.open(file, '_blank')
      } else {
        let publicToken = file.token || this.user.token
        window.open(ServiceStorage.getFileUrl(file, publicToken), '_blank')
      }
    },
    comment() {
      //console.log('comment', this.nodeId)
      this.$emit('comment', this.editor.options.commentFunction(this.nodeId))
    },
    changeActive(active) {
      this.isActive = active
    },
    selectBlock() {
      //count words on block
      let chars = this.node.content.size
      let text = ''
      this.node.content.content.forEach(node => {
        text += node.text
      })
      let words = text.split(' ').length
      let data = {
        id: this.node.attrs.id,
        type: this.node.type.name,
        words: words,
        chars: chars,
      }
      if (this.editor && this.editor.options && this.editor.options.selectBlockCallback) {
        //console.log('inner node selectBlock', data)
        this.editor.options.selectBlockCallback(data)
      }
    },
    addBlock(nodeId, blockType) {
      let endPos = this.getPos() + this.node.nodeSize
      //console.log('addBlock', nodeId, blockType, endPos)
      let content = {
        type: blockType,
      }
      if (blockType == 'duplicate') {
        this.editor
          .chain()
          .insertContentAt(endPos, this.node.toJSON())
          .focus(endPos)
          .run()
        return
      }
      if (blockType == 'heading') {
        content.attrs = { level: 1 }
      } else if (blockType == 'taskList') {
        content.content = [
          {
            type: 'taskItem',
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ]
      } else if (blockType == 'orderedList' || blockType == 'bulletList') {
        content.content = [
          {
            type: 'listItem',
            content: [
              {
                type: 'paragraph',
              },
            ],
          },
        ]
      } else if (blockType == 'blockquote') {
        content.content = [
          {
            type: 'paragraph',
          },
        ]
      } else if (blockType == 'table') {
        //TODO:
        content.content = [
          {
            type: 'tableRow',
            content: [
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
              },
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
              },
            ],
          },
          {
            type: 'tableRow',
            content: [
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
              },
              {
                type: 'tableCell',
                content: [
                  {
                    type: 'paragraph',
                  },
                ],
              },
            ],
          },
        ]
      }

      this.editor
        .chain()
        .insertContentAt(endPos, content)
        .focus(endPos)
        .run()
      this.$refs.addblock.toggle()
      //this.editor.chain().focus().insertContentAt(nodeId, { type: blockType }).run()
    },
    deleteBlock() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_block_title'),
        message: this.$t('delete_block_message'),
        confirmText: this.$t('delete_block_confirm'),
        type: 'is-danger',
        onConfirm: () => {
          this.deleteNode()
        },
      })
    },
    async uploadFile(files) {
      if (files.length > 0) {
        let fileKey = files[0].response.data.file.key
        //console.log('uploadFile', files[0].response.data.file)
        //associate file with page version
        let addFileResponse = await ServicePages.addFiles(this.pageKey, this.versionKey, [fileKey])
        //console.log('addFileResponse', addFileResponse)
        //replace old file with new file
        Vue.set(this.filesCache, fileKey, addFileResponse[0])
        //get file preview
        this.updateAttributes({
          src: files[0].response.data.file,
        })
      }
    },
    async loadFileToCache(file) {
      if (!file) return
      if (typeof file == 'string' && file.startsWith('data:')) return
      let fileInCache = this.filesCache[file.key]
      if (!fileInCache) {
        //get file token
        let getFileResponse = await ServicePages.getFiles(this.pageKey, this.versionKey, [file.key])
        //console.log('getFileResponse', getFileResponse[0])
        //getFileResponse is a dictionary with the file key as the key
        fileInCache = getFileResponse[0]
        //add file to files list
        //this.filesCache[file.key] = fileInCache
        //force view update
        Vue.set(this.filesCache, file.key, fileInCache)
      }
    },
    getImageSrc(file) {
      if (typeof file == 'string' && file.startsWith('data:')) return file
      //check if we have the file in the files list
      //console.log('getImageSrc', file)
      let fileInCache = this.filesCache[file.key]
      return ServiceStorage.getImageViewUrl(fileInCache, null, null)
    },
    getFileViewUrl(file) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getFileUrl(file, publicToken)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "blocks": {
      "image": "Image",
      "video": "Video",
      "blockquote": "Block Quote",
      "bulletList": "Bullet List",
      "orderedList": "Ordered List",
      "taskList": "Todo List",
      "table": "Table",
      "heading": "Heading",
      "codeBlock": "Code Block",
      "paragraph": "Paragraph"
    },
    "duplicate": "Duplicate",
    "delete": "Delete",
    "comment": "Comment",
    "content_block": "Content block",
    "upload_image": "Upload Image",
    "upload_video": "Upload Video",
    "delete_block_title": "Delete block",
    "delete_block_message": "Are you sure you want to delete this block?",
    "delete_block_confirm": "Delete",
    "delete_block_cancel": "Cancel",
    "download_image": "Download Image"
  },
  "pt": {
    "blocks": {
      "image": "Imagem",
      "video": "Vídeo",
      "blockquote": "Citação",
      "bulletList": "Lista",
      "orderedList": "Lista ordenada",
      "taskList": "Lista de tarefas",
      "table": "Tabela",
      "heading": "Título",
      "codeBlock": "Bloco de código",
      "paragraph": "Parágrafo"
    },
    "duplicate": "Duplicar",
    "delete": "Eliminar",
    "comment": "Comentar",
    "content_block": "Bloco de conteúdo",
    "upload_image": "Carregar Imagem",
    "upload_video": "Carregar Vídeo",
    "delete_block_title": "Eliminar bloco",
    "delete_block_message": "Tem certeza que deseja eliminar este bloco?",
    "delete_block_confirm": "Eliminar",
    "delete_block_cancel": "Cancelar",
    "download_image": "Descarregar Imagem"
  }
}
</i18n>
